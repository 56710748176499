<template>
  <div>
    <v-btn
      depressed
      :class="[
        complaint.case_status == 'Ongoing' ||
        complaint.case_status == 'Settled' ||
        complaint.case_status == 'Not yet executed' ||
        complaint.case_status == 'Executed' ||
        complaint.case_status == 'Dismissed' ||
        complaint.case_status == 'Withdrawn' ||
        complaint.case_status == 'Unsettled' ||
        complaint.case_status == 'Repudiated'
          ? 'black--text rounded-xl ml-1'
          : 'white--text rounded-xl ml-1',
      ]"
      small
      @click="open_dialog"
    >
      <v-icon class="pr-1" small dark>mdi-pencil-outline </v-icon>
      Change Status
    </v-btn>
    <!-- CHANGE STATUS DIALOG -->
    <v-dialog v-model="dialog" width="400px" persistent v-if="dialog">
      <v-card flat width="650px" class="pb-3">
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text ml-3">Select Status</h3></v-sheet
        >
        <v-card-text>
          <v-radio-group
            class="mt-6 ml-8"
            dense
            hide-details=""
            v-model="selected_status"
            v-for="x in status"
            :key="x.text"
          >
            <v-radio :color="x.color" :value="x" :label="x.text"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-xl"
            width="90px"
            color="deep-purple accent-4"
            dark
            @click="change(selected_status.text)"
          >
            proceed
          </v-btn>
          <v-btn width="90px" class="rounded-xl" @click="dialog = false">
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="date_settled_dialog" max-width="700px" persistent>
      <v-card>
        <v-sheet
          color="deep-purple accent-4"
          class="pt-2 d-flex justify-center"
          height="40px"
          ><h3 class="white--text">
            Please enter required information
          </h3></v-sheet
        >
        <div>
          <v-col cols="10" offset="1" class="mt-6">
            <v-text-field
              readonly
              dense
              outlined
              v-model="current_date"
              label="Date Settled"
            ></v-text-field>
            <agreement-field @done_agreement="done_agreement" />
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="rounded-xl"
              width="70px"
              color="deep-purple accent-4"
              dark
              @click="add_settled()"
            >
              save
            </v-btn>
            <v-btn class="rounded-xl" @click="date_settled_dialog = false">
              cancel
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- <add-lupon-modal
      :dialog="add_lupon_dialog"
      @close_add_lupon_dialog="close_add_lupon_dialog"
      :item="table_to"
    /> -->
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>
<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
// import AddLuponModal from "@/components/AddLuponModal.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import AgreementField from "./AgreementField.vue";
export default {
  components: {
    ProgressLinear,
    // AddLuponModal,
    AgreementField,
  },
  data() {
    return {
      dialog: false,
      progress_dialog: false,
      add_lupon_dialog: false,
      date_settled_dialog: false,
      agreement: "",
      current_date: moment(new Date()).format("YYYY-MM-DD"),
      table_to: "",
      corresponding_icons: [
        { text: "Withdrawn", icon: "mdi-pencil" },
        { text: "Conciliation", icon: "mdi-magnify" },
        { text: "Arbitration", icon: "mdi-account" },
        { text: "Unsettled", icon: "mdi-mail" },
        { text: "Settled", icon: "mdi-google" },
        { text: "Dismissed", icon: "mdi-plus" },
      ],

      mediation_status: [
        { text: "Withdrawn", icon: "mdi-pencil", color: "blue" },
        {
          text: "Conciliation",
          icon: "mdi-account-group-outline",
          color: "orange",
        },
        { text: "Arbitration", icon: "mdi-gavel", color: "pink" },
        { text: "Unsettled", icon: "mdi-mail", color: "red" },
        { text: "Settled", icon: "mdi-google", color: "green" },
        { text: "Dismissed", icon: "mdi-plus", color: "red" },
      ],
      conciliation_status: [
        { text: "Withdrawn", icon: "mdi-pencil", color: "blue" },
        { text: "Arbitration", icon: "mdi-account", color: "pink" },
        { text: "Unsettled", icon: "mdi-mail", color: "red" },
        { text: "Settled", icon: "mdi-google", color: "green" },
        { text: "Dismissed", icon: "mdi-plus", color: "red" },
      ],
      arbitration_status: [
        { text: "Withdrawn", icon: "mdi-pencil", color: "blue" },
        { text: "Unsettled", icon: "mdi-mail", color: "red" },
        { text: "Settled", icon: "mdi-google", color: "green" },
        { text: "Dismissed", icon: "mdi-plus", color: "red" },
      ],
      execution_status: [
        { text: "Not yet executed", icon: "mdi-mail", color: "red" },
        { text: "Executed", icon: "mdi-google", color: "green" },
      ],
      selected_status: "",
      judges: [],

      icons: [],
    };
  },
  computed: {
    ...mapGetters({
      complaint: "complaint/complaint",
      user: "auth/user",
    }),
    status() {
      let status = null;
      if (this.complaint.current_table == "Mediation")
        status = this.mediation_status;
      if (this.complaint.current_table == "Conciliation")
        status = this.conciliation_status;
      if (this.complaint.current_table == "Arbitration")
        status = this.arbitration_status;
      if (this.complaint.current_table == "Execution")
        status = this.execution_status;
      return status;
    },
  },
  methods: {
    open_dialog() {
      this.dialog = true;
    },
    status_unchangeable(msg) {
      this.set_notification({
        message: msg,
        color: "red",
        showing: true,
        icon: "mdi-close",
      });
      return;
    },
    done_agreement(agreement) {
      this.agreement = agreement;
    },
    get_icon(x) {
      this.corresponding_icons.forEach((item) => {
        if (x == item.text) {
          console.log(item.icon);
          return String(item.icon);
        }
      });
    },
    ...mapActions({
      get_lupons: "lupon/get_lupons",
      change_status: "complaint/change_status",
      change_settled_status: "complaint/change_settled_status",
      add_agreement: "complaint/add_agreement",
      edit_execution: "execution/edit_execution",
      set_notification: "notification/set_notification",
      add_unsettled: "unsettled/add_unsettled",
      add_credit: "lupon/add_credit",
      add_arbitration: "arbitration/add_arbitration",
      add_execution: "execution/add_execution",
      add_notification: "notification/add_notification",
      get_notifications: "notification/get_notifications",
    }),
    close() {
      this.$emit("close_edit_modal", false);
    },
    open_add_lupon() {
      this.add_lupon_dialog = true;
    },
    close_add_lupon_dialog() {
      this.add_lupon_dialog = false;
    },
    async change(text) {
      if (text == "Settled") {
        this.date_settled_dialog = true;
        return;
      }
      if (text == "Not yet executed" || text == "Executed") {
        let obj = {
          complaint_id: this.complaint.id,
          case_status: text,
        };
        this.progress_dialog = true;
        await this.change_settled_status(obj);
        this.progress_dialog = false;
        this.dialog = false;
        this.set_notification({
          message: "Complaint status successfully changed!",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        return;
      }
      if (
        this.complaint.current_table == "Conciliation" &&
        this.complaint.conciliation.conciliators == null
      ) {
        this.dialog = false;
        this.set_notification({
          message: "You cannot change complaint status without conciliators",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return;
      }
      if (
        this.complaint.current_table == "Conciliation" &&
        this.complaint.conciliation.date_of_first_hearing == null
      ) {
        this.dialog = false;
        this.set_notification({
          message: "You cannot change complaint status without date of hearing",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return;
      }

      if (
        text == "Withdrawn" ||
        text == "Unsettled" ||
        text == "Dismissed" ||
        text == "Repudiated"
      ) {
        this.table_to = "Unsettled";
      } else {
        this.table_to = text;
      }

      this.progress_dialog = true;
      let obj = {
        complaint_id: this.complaint.id,
        table_name: text,
        exclusive_case_no: this.complaint.exclusive_case_no,
      };

      await this.change_status(obj);
      await this.get_notifications();
      this.progress_dialog = false;
      this.dialog = false;
      this.set_notification({
        message: "Complaint is successfully referred to " + this.table_to + ".",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
    },
    async add_settled() {
      if (this.agreement == "") {
        this.set_notification({
          message: "Click done first before proceeding",
          color: "red",
          showing: true,
          icon: "mdi-close",
        });
        return;
      }
      this.progress_dialog = true;
      let obj = {
        complaint_id: this.complaint.id,
        table_name: "Settled",
        exclusive_case_no: this.complaint.exclusive_case_no,
      };
      let obj2 = {
        complaint_id: this.complaint.id,
        table_name: "Settled",
        agreement: JSON.stringify(this.agreement),
      };
      await this.change_status(obj);
      await this.add_agreement(obj2);
      await this.get_notifications();
      this.date_settled_dialog = false;
      this.progress_dialog = false;
      this.dialog = false;
      this.set_notification({
        message: "Complaint successfully transferred to settled table",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });
    },
  },
};
</script>
