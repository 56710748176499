<template>
  <div>
    <p>Agreement Inputs:</p>
    <v-row v-for="(input, index) in agreement_array" :key="index">
      <v-col cols="10">
        <v-textarea
          :disabled="!editable"
          prepend-icon="mdi-draw-pen"
          class="body-2 rounded-xl"
          :rules="[rules.required]"
          hide-details=""
          dense
          label="Agreement"
          placeholder="Enter agreement here..."
          v-model="input.agreement"
        >
        </v-textarea>
      </v-col>
      <v-col>
        <v-icon
          @click="remove_agreement(index)"
          color="deep-purple accent-4"
          v-show="index || (!index && agreement_array.length > 1)"
          >mdi-minus-circle</v-icon
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" align="end" justify="end">
        <v-icon @click="add_agreement" color="deep-purple accent-4"
          >mdi-plus-circle</v-icon
        >
      </v-col>
    </v-row>
    <div class="mt-4 d-flex">
      <v-btn class="rounded-xl mr-1" color="yellow" small @click="edit"
        >Edit</v-btn
      >
      <v-btn class="rounded-xl" color="primary" small @click="done">Done</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    editable: true,
    rules: {
      required: (value) => !!value || "Required.",
    },

    agreement_array: [
      {
        agreement: "",
      },
    ],
  }),
  methods: {
    done() {
      this.editable = false;
      this.$emit("done_agreement", this.agreement_array);
    },
    edit() {
      this.editable = true;
    },
    add_agreement() {
      this.agreement_array.push({
        agreement: "",
      });
    },
    remove_agreement(i) {
      this.agreement_array.splice(i, 1);
    },
  },
};
</script>

<style></style>
